export const handleUploadFile = async (file: any) => {
    // const uploadDetails = await getUploadDetails() as any;
    const uploadUrl = process.env.REACT_APP_UPLOAD_URL as string;
    const data = new FormData();
    data.append("file", file);

    var requestOptions = {
        method: 'POST',
        body: data,
      };

      return (await fetch(uploadUrl, requestOptions)).json()
} 

