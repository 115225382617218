import React, { useRef, useState } from 'react';
import './App.css';
import { FileUploader } from "react-drag-drop-files";
import clear from "./assets/clear.png";
import upload from "./assets/upload.png";
import { handleUploadFile } from './utils';

const fileTypes = ["JPEG", "JPG", "PNG", "GIF"];

function App() {
  const [file, setFile] = useState<any[]>([]);
  const [results, setResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const refFrom = useRef<any>(null);
  

  const handleSetLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  }

  const filterDuplicate = (_file: any) => {
    const fileInputArray = Array.from(_file);
    const fileArray = Array.from(file);


    return fileInputArray.filter((item: any) => {
      const isDuplicate = fileArray.find((el: any) => item.name === el.name && item.size === el.size && item.type === el.type) as any
      if (isDuplicate) {
        window.alert(`Duplicate file "${item.name}"`);
      }

      return !isDuplicate;
    })
  }

  const handleChange = (_file: any) => {
    if (_file) {
      const files = (file).concat(...filterDuplicate([_file]));

      const lastFile = files[files.length - 1]
      setFile([lastFile]);
    }
  };

  const handleRemoveItem = (index: number) => {
    if (loading) return;
    delete file[index];
    if (refFrom.current) refFrom.current.reset();
    setFile(file.filter(item => item))
  }

  const handleFileSizeError = (err: unknown) => {
    window.alert("File size must be less than or equal to 5MB");
  }

  const handleBtnUploadFile = async () => {
    if (loading) return;
    if (!file.length) return;

    try {
      handleSetLoading(true);
      const results = await Promise.all(file.map(item => {
        return handleUploadFile(item)
      }));
      setResults(results);
      setFile([]);
      if (refFrom.current) refFrom.current.reset();
    } catch (error: any) {
      window.alert(error?.message || "Unknown error")
    } finally {
      handleSetLoading(false);
    }
  }

  return (
     <form ref={refFrom} onSubmit={(e) =>  e.preventDefault()} className="App">
     <FileUploader
        multiple={false}
        handleChange={handleChange}
        name="file"
        hoverTitle="Drop here"
        types={fileTypes}
        label="Upload or drop a file right here"
        classes="b2-upload-files"
        maxSize={5}
        onSizeError={handleFileSizeError}
      >
        <div className="box_body">
          <svg className="box_icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43">
            <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z">
            </path>
          </svg>
          <p>Drop files {fileTypes.join(", ").toLowerCase()} here</p>
          <div className="body_btn_upload">
            Or <label htmlFor="files">Choose Files »</label>
          </div>
          <p className="box_text_description">The size of image should be <b>200x200</b></p>
        </div>
      </FileUploader>

      {
        !!file?.length && <div className="file_table">
          {file.map((item: any, index) => {
            return (
              <div className="file_table_item" key={index}>
                <div>{item.name}</div>
                <div className='remove_item' onClick={() => handleRemoveItem(index)}>
                  <img src={clear} alt="clear" />
                </div>
              </div>
            );
          })}
        </div>
      }
      <br />
      {
        !!results?.length && 
          results.map((item: any, index) => {
            return (
                <div className="file_table_item" key={index}>
                  <br />
                  <a target="_blank" rel="noreferrer" href={item.url}>{item.url}</a>
                </div>
            );
          })
      }

      <button type="button" className={"box_button" + (loading ? " disabled" : "")} onClick={handleBtnUploadFile} id="buttonUpload">
        {loading && <>
          <svg className="loading-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </>}
        Upload
        <img src={upload} alt="upload" />
      </button>
     </form>

     


  );
}

export default App;
